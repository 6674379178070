#treasury-dashboard-view {

  overflow-y: hidden;
  overflow-x: hidden;
  //height: 100%;
  //width: 100%;
  //min-width: 300px;
  margin-top: 140px;
  display: flex;
  //flex-direction: column;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }


  .dashboardCard {
    display: flex;
    //margin-top: 6px;
    justify-content: space-evenly;
    flex-direction: row;
    margin: unset;
  }

  .ohm-card-1-f {
    // background-image: url(../../assets/dashboard_1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 17px;
    min-width: 311px;
    max-width: 311px;
    min-height: 410px;
    max-height: 410px;
    box-shadow: 10px 10px 5px #1a18188a;
  }

  .ohm-card-1 {

    display: flex;
    flex-direction: column;

    backdrop-filter: unset;
    background-color: unset;

    margin-top: 34px;
    margin-left: 26px;
    margin-right: 21px;

    .Balances {
      font-size: 18px;
      font-family: Kano;
      font-weight: 400;
      color: #8B8B8B;
    }

    .ohm-card-top {

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h6,
      h5 {
        font-size: 16px;
        font-family: Kano;
        font-weight: 400;
        color: #FFFFFF;
        //align-self: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .total-value {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .M-B-D {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .s-M-B-D {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

    }

    .Rewards {
      font-size: 18px;
      font-family: Kano;
      font-weight: 400;
      color: #8B8B8B;
      margin-top: 35px;
    }

    .ohm-card-bottom {

      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h6,
      h5 {
        font-size: 16px;
        font-family: Kano;
        font-weight: 400;
        color: #FFFFFF;
        //align-self: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .est-daily {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .next-epoch {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .in-warmup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }


    }


  }

  .ohm-card-2-f {
    // background-image: url(../../assets/dashboard_2.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 17px;
    min-width: 311px;
    max-width: 311px;
    min-height: 410px;
    max-height: 410px;
    box-shadow: 10px 10px 5px #1a18188a;
  }

  .ohm-card-2 {

    backdrop-filter: unset;
    background-color: unset;

    margin-top: 34px;
    margin-left: 26px;
    margin-right: 21px;

    .Minting {
      font-size: 18px;
      font-family: Kano;
      font-weight: 400;
      color: #8B8B8B;
    }

    .ohm-card-top {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h6,
      h5 {
        font-size: 16px;
        font-family: Kano;
        font-weight: 400;
        color: #FFFFFF;
        //align-self: flex-end;
        margin-top: 10px;
        margin-bottom: 60px;
      }

      .pend-ing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .claim-able {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .stak-ing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

    }

    .Rewards {
      font-size: 18px;
      font-family: Kano;
      font-weight: 400;
      color: #8B8B8B;
      margin-top: 35px;
    }

    .ohm-card-bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-around;


      h6,
      h5 {
        font-size: 16px;
        font-family: Kano;
        font-weight: 400;
        color: #FFFFFF;
        //align-self: flex-end;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .to-tal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .in-warmup {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }


  }

  .ohm-card-3-f {
    // background-image: url(../../assets/dashboard_3.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 17px;
    min-width: 311px;
    max-width: 311px;
    min-height: 410px;
    max-height: 410px;
    box-shadow: 10px 10px 5px #1a18188a;
  }

  .ohm-card-3 {

    backdrop-filter: unset;
    background-color: unset;
    margin-top: 34px;
    margin-left: 26px;
    margin-right: 21px;

    .ProtocolStatistics {
      font-size: 18px;
      font-family: Kano;
      font-weight: 400;
      color: #8B8B8B;
    }

    .ohm-card-top {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      h6,
      h5 {
        font-size: 16px;
        font-family: Kano;
        font-weight: 400;
        color: #FFFFFF;
        //align-self: flex-end;
        margin-top: 8px;
      }

      .current-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .a-p-y {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .day-rate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

      .market-cap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

      .circ-supply {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

      .trea-sury {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .t-v-l {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;
      }

      .backing-MBD {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

      .run-way {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }

      .M-B-D {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //width: -webkit-fill-available;  
      }


    }
  }

  .hero-metrics {
    width: 100%;
    margin-bottom: 23px;
    text-align: center;

    .ohm-card {
      max-width: unset !important;
      flex-direction: row;
      justify-content: space-evenly;
      min-width: none;

      h4 {
        font-weight: bold;
      }

      div.metric {
        margin: 3px;
      }
    }
  }

  .data-grid {
    overflow: hidden;
    justify-content: center;
  }
}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 960px) {

  #treasury-dashboard-view {
    margin-top: unset;

    .dashboardCard {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
    }

    .ohm-card-1-f {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //background-size: contain;
      margin-bottom: 10px;

      .ohm-card-1 {
        //height: 250px;  
        // padding-top: 24px;
        // margin-left: unset;
        // margin-right: unset;
        //width: 290px;


        h6,
        h5 {
          //align-self:flex-end;
          // margin-top: unset;
          // margin-bottom: unset;
        }

      }
    }

    .ohm-card-2-f {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      //background-size: contain;
      margin-bottom: 10px;

      .ohm-card-2 {

        //height: 250px;  
        //padding-top: unset;
        // margin-left: unset;
        // margin-right: unset;  
        //width: 290px;
        h6,h5 {
          //align-self:flex-end;
          // margin-top: unset;
          // margin-bottom: unset;
        }
      }

    }

    .ohm-card-3-f {
      display: flex;
      justify-content: center;
      flex-direction: column;
      justify-content: flex-start;

      .ohm-card-3 {  

        .ohm-card-top {
          h6,h5 {
            align-self: center;
          }     
        }

      }
    }


  }
}


.smaller {
  .ohm-card {
    width: 97%;
  }

  .hero-metrics .ohm-card {
    >.MuiBox-root {
      flex-wrap: wrap;
    }

    div.metric {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 30% !important;
    max-width: 60%;
    text-align: left !important;

    &.circ,
    &.market {
      width: 67% !important;
    }

    &.price,
    &.index {
      width: 33% !important;
    }
  }
}

.very-small {
  .hero-metrics .ohm-card {
    >.MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}