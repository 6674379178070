.dapp-topbar {
  width: 100%;
  max-width: 1341px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between !important;

  .logo-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 98px;
      // height: 26px;
      margin-right: 30px;
    }

    .link-container {

      .link-item {
        margin-left: 30px;

        h6 {
          color: #BABABA;
        }
      }
    }
  }
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
}
.tablet .dapp-topbar {
  justify-content: space-between;
}
.pending-txn-container {
  display: flex;
  align-items: center;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
  }
}
#ohm-popper-pending {
  .MuiPaper-root.ohm-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}

.ssohm-input {
  width: 100%;
  margin: 5px   !important;
}

.MuiAppBar-positionSticky {
  right: 200px;
}



@media only screen and (max-width: 1200px) {
  .dapp-topbar {
    width: 100%;
    align-items: center;
    justify-content: space-between !important;
  
    .logo-container {
      width: 100%;
      height: 100%;
      padding-left: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .link-container {
  
        .link-item {
          margin-left: 30px;
  
          h6 {
            color: #BABABA;
          }
        }
      }
    }
    button {
      white-space: nowrap !important;
      overflow: hidden !important;
      margin: 6px !important;
      min-width: fit-content !important;
      &#hamburger {
        padding: 11px !important;
      }
      &.toggle-button {
        padding: 9.5px !important;
      }
    }
  }
}


@media only screen and (max-width: 700px) {
  .dapp-topbar {
  
    .logo-container {
  
      .logo {
        width: 60px;
        margin-right: 15px;
      }
  
      .link-container {
  
        .link-item {
  
          h6 {
            font-size: 7px;
            color: #BABABA;
          }
        }
      }
    }
  }
}
