@media only screen and (max-width: 500px) {
  .choose-bond-view {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;

    .bond-container {

      .bond-top {
        margin-top: 8px;
        margin-bottom: 26px;
        border-bottom: 2px solid #727272;
        flex-wrap: wrap;
        flex-direction: column;

        .bond-top-left {
          width: 60%;
          flex-wrap: wrap;

          .left-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-right: 0;
            margin-bottom: 16px;

            .left-label {
              margin-left: 0;
              font-size: 18px;
              font-family: Kano;
              font-weight: 400;
              color: #8A8A8A;
              line-height: 35px;
            }

            .left-value {
              padding: 8px 26px 8px 0;
              background: rgba(61, 61, 61, 0.3);;
              border-radius: 10px;
              font-size: 30px;
              font-family: Helvetica;
              font-weight: bold;
              color: #FFFFFF;
            }
          }
        }

        .bond-top-right {
          width: 100%;
          justify-content: start;

          .bond-top-btn {
            width: 74px;
            height: 32px;
            margin-right: 12px;
            border: 1px solid #E3C990;
            border-radius: 5px;

            font-size: 18px;
            font-family: Kano;
            font-weight: bold;
            color: #FFFFFF;
            cursor: pointer;
          }
          .active {
            background: #E3C990;
            color: #000000;
          }
        }
      }

      .bond-bot {
        width: 100%;

        .bot-left {
          width: 396px;
        }

        .bot-right {
          width: 486px;
          margin-right: 0;
        }
      }
    }

    .redeem-table-container {
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .bond-redeem-table {
      flex: 1;
      font-family: Myriad Pro;
      font-weight: 400;
      text-align: center;
  
      .bond-table-header {
        margin-bottom: 14px;
        display: flex;
        justify-content: center;
        font-size: 18px;
        color: #A1A1A1;
  
        .header-25 {
          width: 25%;
        }
      }
  
      .bond-table-body {
  
        .body-item {
          padding: 8px;
  
          justify-content: center;
          font-size: 16px;
          cursor: pointer;
  
          .body-item-25 {
            width: 25%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .MuiTableBody-root .MuiTableRow-root {
      padding: 10px 30px 10px 16px !important;
      background: rgba(61, 61, 61, 0.9) !important;
      border-radius: 10px !important;
    }
  }

  .width100 {
    width: 100% !important;
  }

  #bond-claim-btn {
    padding: 0 8px !important;
  }

  #bond-claim-autostake-btn {
    width: 180px !important;
    height: 40px !important;
    border-radius: 24px !important;
  }

  .transaction-button {
    // min-width: 185px !important;
    // max-width: 408px;
    width: 100% !important;
    height: 50px !important;
    max-height: 50px !important;
    margin-bottom: 28px;
    // margin: 5px !important;
  }

  #bond-detail-btn {
    width: 60px !important;
    height: 30px !important;
  }
}
