.loading-header {
  padding: 19px 15px 11px;
  background: #2C3F6D;
  box-shadow: 0px 0px 36px 2px rgba(0,0,0,0.19);
  border-radius: 10px 10px 0px 0px;

  :nth-child(1) {
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    color: #FFFFFF;
  }

  :nth-child(2) {
    width: 20px;
    height: 21px;
    cursor: pointer;
  }
}

.loading-content {
  padding: 40px 15px;
  background: #374A7B;
  box-shadow: 0px 0px 36px 2px rgba(0,0,0,0.19);
  border-radius: 0px 0px 10px 10px;

  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  color: #6F84B6;

  .reject-tip {
    margin: 23px 0 21px;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    color: #F60A66;
  }

  img {
    width: 78px;
    height: 78px;
  }
}