@media only screen and (max-width: 550px) {
  .time-container {
    padding-left: 0;
    margin-left: 0;
    margin-top: 8px;
    border-left: none;
    font-family: Poppins;
  
    .rebase-text {
      margin-right: 4px;
      font-size: 12px;
    }
    
    .countItem-text {
      width: 20px;
      height: 20px;
      font-size: 12px;
      // -webkit-transform-origin-x: 0;
      // -webkit-transform-origin-y: 0;
      // transform: scale(0.5);
    }
    .time-unit {
      margin: 0 2px;
      font-size: 12px;
      // -webkit-transform-origin-x: 0;
      // -webkit-transform-origin-y: 0;
      // transform: scale(0.5);
    }
  }
  
}
