@media only screen and (max-width: 1341px) {
  .dashboard-view {
    width: 100%;
    margin-top: 30px;
    padding: 0 20px;
  
    .bg-container {
      background: #141E2F;
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
      border-radius: 10px;
    }
  
    .dashboard-title {
      margin-bottom: 24px;
  
      h2 {
        font-size: 16px;
        font-family: Poppins;
      }
    }
  
    .chart-container {
      padding: 16px;
      margin: 0 auto;
  
      img {
        width: 15px;
        height: 15px;
        margin-left: 8px;
      }
    }
  
    .card-container {
      justify-content: space-between;
      margin: 18px 0;

      .card-item {
        width: 153px;
        height: 81px;
        margin-left: 0;
        margin-bottom: 15px;
  
        :nth-child(1) {
          font-size: 12px;
        }
  
        h6 {
          font-size: 11px;
        }
  
        h5 {
          font-size: 17px;
          margin-top: 15px;
        }
      }
  
      :nth-child(3) {
        margin-bottom: 0;
      }
  
      :nth-child(4) {
        margin-bottom: 0;
      }
    }
  
    .data-container {
      margin-top: 0;
      border-radius: 10px;
      background: #141E2F;
  
  
      .data-item {
        width: 90%;
        margin: 0 auto;
        padding: 10px 0;
        border-bottom: 1px solid #3D4F6D;
  
        h5 {
          font-size: 10px;
        }
      }
  
      :last-child {
        border-bottom: none;
      }
    }
  }
  
}
