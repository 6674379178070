@media only screen and (max-width: 1000px) {
  #footer-view {
    padding: 44px 22px;
    margin-top: 20px;

    .footer-container {
      max-width: 100%;
      margin: 0 auto;
    }

    .footer-content {
      flex-wrap: wrap;
      padding-bottom: 20px;
      border-bottom: 2px solid #6A6A6A;

      .footer-left {
        width: 100%;
        max-width: 100%;
        padding-top: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;

        .desc {
          margin: 19px 0 6px;
          font-size: 6px;
        }

        .logo {
          width: 81px;
          // height: 17px;
        }

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }

        .language {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-left: 32px;
        }
      }

      .footer-right {
        width: 100%;
        margin-left: 0;
        margin-top: 17px;
        flex-direction: column;

        .title {
          font-size: 9px;
          width: 50px;
        }
        .right-item {
          flex-direction: row;
        }
        .item {
          flex: 1;
          display: flex;
          // justify-content: space-between;
          margin-top: 0px;
          // margin-left: 12px;

          div {
            margin-left: 20px;
            margin-bottom: 21px;
            font-size: 8px;
          }
        }

        .active-item {
          div {
            font-weight: 600;
            color: #FFF;
          }
        }
      }
    }

    .footer-symbol {
      margin-top: 10px;
      font-size: 8px;
    }

    .lang-select {
      width: 116px;
      height: 22px;
      padding: 0 3px 0 6px;
      margin-top: 11px;
      border-radius: 11px;
      font-size: 8px;

      img {
        width: 14px;
        height: 14px;
      }
    }

    .MuiSelect-select:focus {
      background: none;
    }
  }
}

.lang-dropdown-app {

  .ant-dropdown-menu-title-content {
    font-size: 12px;
    line-height: 16px;
  }
}
