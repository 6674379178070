.ido-info-view {
	height: 100%;
	margin-right: 22px;

	.ido-top {
		// border-bottom: 1px solid #A1A1A1;

		.ido-finish {

			img {
				width: 691px;
				height: 220px;
				margin: 0 auto;
			}
		}
	}

	.ido-bot {
		flex: 1;
		margin-top: -20px;

		.bot-left {

			.left-item {
				margin-bottom: 36px;
			}

			.date-container {
				margin-top: 20px;
				font-size: 12px;
				font-family: Poppins;
				font-weight: 500;
				color: #E1E1E1;

				.finish-text {
					width: 200px;
					text-align: center;
					font-size: 14px;
					font-family: Poppins;
					font-weight: 400;
					color: #C2C2C2;
				}

				img {
					margin-right: 12px;
				}
			}
		}

		.bot-right {
			transform: scale(1.5);
			
			.my-balance {
				margin-bottom: 12px;
				font-size: 14px;

				:nth-child(1) {
					font-family: Poppins;
					font-weight: 300;
					color: #979797;
				}
				:nth-child(2) {
					font-family: Helvetica;
					font-weight: 300;
				}
			}

			.swap-title {
				font-size: 36px;
				font-family: Poppins;
				font-weight: 600;
				color: #FFFEFE;
			}

			.card-container {
				margin: 23px 0 26px;
			}

			.swap-card {
				width: 133px;
				height: 150px;
				padding: 23px 30px 25px 13px;
				background: rgba($color: #FFF, $alpha: .05);
				border-radius: 10px;

				.card-text {
					font-size: 12px;
					font-family: Poppins;
					font-weight: 400;
					color: #0CD4F3;
				}

				h6 {
					font-family: Poppins;
					font-weight: 500;
					color: #E1E1E1;
				}

				img {
					width: 43px;
					height: 43px;
				}
			}

			.swap-arrow {
				margin: 0 20px;

				img {
					width: 14px;
					height: 12px;
				}
			}
		}

		.check-container {
			margin-bottom: 42px;
			font-size: 16px;
			font-family: Poppins;

			.check-icon {
				// width: 15px;
				// height: 15px;
				margin-right: 15px;
			}
		}
	}

	img {
		width: 15px;
		height: 15px;
	}

	.ido-input {
		width: 322px;
		margin-bottom: 24px;
		border-radius: 10px;
	}
	.ido-button {
		width: 100%;
		height: 54px;
		border-radius: 10px;
	}
}
