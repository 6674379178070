.stake-view {
  width: 1341px;
  margin-top: 50px;

  .bg-container {
    background: #1B283F;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
    border-radius: 10px;
  }

  .color-bg-container {
    border: NaNpx solid;
    border-image: linear-gradient(0deg, #269AD2, #13FAD1) 10 10;
    background: linear-gradient(0deg, #163B4C 1%, #19403D 100%);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
    border-radius: 10px;
  }

  .unity-tab-container {
    position: relative;
    margin-bottom: 23px;

    .unity-calculator {
      position: absolute;
      top: 0;
      right: 2px;

      padding: 7px 10px 10px;
      border: 1px solid #575757;
      border-radius: 5px;
      cursor: pointer;

      img {
        width: 18px;
        height: 20px;
      }
    }
  }

  .unity-top {

    .top-item {
      width: 29.16%;
      height: 204px;

      .item-label {
        margin-bottom: 32px;
        font-size: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #70798A;
      }
      .item-value {
        font-size: 32px;
        font-family: Poppins;
        font-weight: 300;
        color: #FFF;
      }
    }

    .lock-container {
      width: 29.16%;
      height: 204px;

      .lock {
        width: 100%;
        height: 100%;
        padding: 25px 24px 15px;
      }

      .balance-title {
        margin-bottom: 26px;
        text-align: center;
        font-size: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #FFFFFF;
      }

      .balance-table {
        margin-bottom: 14px;

        .table-header {
          .header-item {
            width: 40%;
            text-align: center;
            font-size: 14px;
            font-family: Poppins;
            font-weight: 300;
            color: #70798A;
          }
        }

        .table-body {
          margin-top: 8px;

          .item-container {
            height: 42px;
            background: rgba(0, 0, 0, .15);
            border-radius: 8px;
          }
          .body-item {
            width: 40%;
            font-size: 14px;
            font-family: Poppins;
            font-weight: 300;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .unity-bot {
    // width: 1352px;
    // height: 470px;
    margin-top: 32px;
    padding: 36px 40px 70px;
    background: #141E2F;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
    border-radius: 10px;

    .mode-tab {

      .mode-item {
        // height: 32px;
        padding: 10px 12px;
        font-size: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #FFFFFF;
        cursor: pointer;
      }

      .mode-item-active {
        border: NaNpx solid;
        border-image: linear-gradient(0deg, #69E2EF, #75FCAA) 10 10;
        background: linear-gradient(90deg, #69E1EF 0%, #74FAA9 100%);
        border-radius: 5px;
        color: #000000;
      }
    }

    .mode-container {
      margin-bottom: 55px;
    }

    .data-list {
      width: 581px;
      padding: 34px 16px 0 30px;

      .left-item {
        margin-bottom: 32px;

        .item-label {
          font-size: 14px;
          font-family: Poppins;
          font-weight: bold;
          color: #B8B8B8;
        }
        .item-value {
          font-size: 14px;
          font-family: Poppins;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }

    .unity-operate {
      width: 581px;
      padding: 28px 36px;

      .operate-title {
        margin-bottom: 33px;
        font-size: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #FFFFFF;
      }
    }
  }
  
  .stake-view-container {
    width: 100%;
    padding: 16px 36px;
  }

  .stake-data-container {
    margin-bottom: 24px;

    .stake-data {
      width: 489px;
      height: 199px;
      padding-left: 18px;
      padding-top: 4px;
      padding-bottom: 8px;
      flex-wrap: wrap;
      background: rgba(61, 61, 61, 0.3);
      border-radius: 10px;

      .stake-data-item {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .item-label {
          font-size: 14px;
          font-family: Kano;
          font-weight: 400;
          color: #8A8A8A;
          line-height: 35px;
        }

        .item-value {
          font-size: 24px;
          line-height: 17px;
          font-family: Helvetica;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }


  .amount-input {
    width: 100%;
    height: 53px;
    border-radius: 5px;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 300;
    color: #70798A;
  }
  .stake-button {
    width: 100%;
    height: 54px;
    min-height: 54px;
    margin-top: 45px;
    border-radius: 5px;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 300;
    color: #FFFFFF;
  }
  
  .stake-note {
    width: 80%;
    text-align: center;
    font-style: italic;
  }
  
  .claim-button {
    width: 100%;
    // min-width: 60px;
    padding: 4px 12px;
    border-radius: 5px;
    font-size: 12px;
    font-family: Poppins;
    color: #FFFFFF;
    cursor: pointer;

    // &:hover {
    //   color: #000;
    //   background: #6AE6F1;
    // }
  }
  .max-button {
    font-size: 16px !important;
  }
}
