.wallet-bt-out {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  .apd-popup {
    display: none;
    position: absolute;
    top: 36px;
    right: -50px;
    padding: 12px;
    background: linear-gradient(180deg, #182438, #141e2f);
    border: 1px solid #38404e;
    border-radius: 10px;
    z-index: 2;

    .item {
      display: flex;
      align-items: center;
      height: 40px;
      background: transparent;
      border-radius: 8px;
      padding-left: 12px;
      padding-right: 38px;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: rgba(255, 255, 255, 0.70);
      white-space: nowrap;
      cursor: pointer;
      position: relative;

      img {
        width: 16px !important;
        height: 16px;
        margin-right: 6px;
      }

      span {
        display: block;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }

      &:hover,
      &:focus {
        background: #1a273c;

        .green {
          background: linear-gradient(270deg, #00ff9f, #08e4f6);
          -webkit-background-clip: text;
          color: transparent;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .title {
      width: 216px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      white-space: nowrap;
    }
  }

  &:hover,
  &:focus {
    .apd-popup {
      display: block;
    }
  }
}

.wallet-bt {
  margin-left: 35px;
  display: flex;
  align-items: center;
  padding: 1px 18px;
  height: 36px;
  // width: 160px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 8px;
  background-image: linear-gradient(270deg, #00ff9f, #08e5f5);
  position: relative;
  z-index: 1;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 7px;
    background-color: #081429;
    z-index: -1;
  }

  img {
    display: block;
    height: 18px !important;
    width: auto !important;
    margin-right: 8px;
  }
}

@media only screen and (max-width: 700px) {
  .wallet-bt {
    height: 24px;
    padding: 1px 9px;
    font-size: 7px;

    img {
      // width: 9px !important;
      height: 8px !important;
      margin-right: 4px;
    }
  }
}
