#calculator-modal {
  width: 720px;
  background: rgba(19, 28, 49, .98);

  .calculator-title {
    width: 100%;
    padding: 24px;

    :nth-child(1) {
      font-size: 30px;
      font-family: Poppins;
      font-weight: 600;
      background: linear-gradient(58deg, #6AE6E8 0%, #75FAAB 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .snowglobe-container {
    padding: 0 17px 40px 50px;

    .form-label {
      margin-top: 20px;
      margin-bottom: 8px;
      h6 {
        font-size: 12px;
      }
    }
    .MuiButton-root {
      font-size: 1rem;
    }
    .sliderContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      .sliderDesc {
          margin-bottom: 8px;
      }
      .MuiSlider-root.MuiSlider-vertical {
        height: 256px;
      }
    }
    .info {
      height: 100%;
      .info-item {
        margin: 10px 0;
        .MuiTypography-colorTextSecondary {
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #calculator-modal {
    width: 100%;
    background: rgba(19, 28, 49, .98);
  
    .calculator-title {
      width: 100%;
      padding: 24px 16px;
  
      :nth-child(1) {
        font-size: 30px;
        font-family: Poppins;
        font-weight: 600;
        color: #FFBA01;
      }
      img {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  
    .snowglobe-container {
      padding: 0 18px 40px;
  
      .form-label {
        margin-top: 20px;
        margin-bottom: 8px;
        h6 {
          font-size: 12px;
        }
      }
      .MuiButton-root {
        font-size: 1rem;
      }
      .sliderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        .sliderDesc {
            margin-bottom: 8px;
        }
        .MuiSlider-root.MuiSlider-vertical {
          height: 256px;
        }
      }
      .info {
        height: 100%;
        .info-item {
          margin: 10px 0;
          .MuiTypography-colorTextSecondary {
            font-size: 13px;
          }
        }
      }
    }
  }
}
