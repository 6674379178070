.topbar-menu {
  width: 500px;
  height: 40px;
  border: 1px solid #575757;
  border-radius: 5px;

  .menu {
    width: 100%;
    height: 100%;
  }

  .menu-item {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: 16px;
    font-family: Poppins;
    font-weight: 300;
    color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
  }

  .menu-item-active {
    border: NaNpx solid;
    border-image: linear-gradient(0deg, #6AE2EF, #74F9AC) 10 10;
    background: linear-gradient(90deg, #6AE5EA 0%, #75FAAA 100%);
    border-radius: 5px;
    font-weight: 400;
    color: #000000;
  }
}

@media only screen and (max-width: 500px) {
  .topbar-menu {
    width: 240px;
    height: 21px;
    margin-bottom: 20px;
    border: 1px solid #575757;
  
    .menu {
      width: 100%;
      height: 100%;
    }
  
    .menu-item {
      width: 100%;
      height: 100%;
      font-size: 8px;
    }
  
    .menu-item-active {
      border: NaNpx solid;
      border-image: linear-gradient(0deg, #6AE2EF, #74F9AC) 10 10;
      background: linear-gradient(90deg, #6AE5EA 0%, #75FAAA 100%);
      border-radius: 5px;
      font-weight: 400;
      color: #000000;
    }
  }
}
