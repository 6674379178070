.time-container {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 2px solid #70798A;
  font-family: Poppins;

  .rebase-text {
    margin-right: 15px;
    font-size: 20px;
    font-weight: 300;
    color: #FFFFFF;
  }
  .countItem-text {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #1B283F;
    border-radius: 3px;

    font-size: 18px;
    font-weight: 300;
    color: #FFF;
  }
  .time-unit {
    margin: 0 7px;
    font-size: 18px;
    font-weight: 300;
    color: #70798A;
  }
}
