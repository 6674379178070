.choose-bond-view {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;

  .bond-container {
    width: 100%;

    .bond-head {
      font-size: 36px;
      font-family: Kano;
      font-weight: bold;
      color: #FFFFFF;
    }

    .bond-top {
      width: 100%;
      padding: 26px 0;
      margin-top: 20px;
      margin-bottom: 26px;
      border-bottom: 2px solid #727272;

      .bond-top-left {
        width: 60%;

        .left-item {
          margin-right: 58px;

          .left-label {
            margin-left: 26px;
            font-size: 18px;
            font-family: Kano;
            font-weight: 400;
            color: #8A8A8A;
            line-height: 35px;
          }

          .left-value {
            padding: 8px 26px;
            background: rgba(61, 61, 61, 0.3);;
            border-radius: 10px;
            font-size: 30px;
            font-family: Helvetica;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }

      .bond-top-right {
        width: 40%;

        .bond-top-btn {
          width: 74px;
          height: 32px;
          margin-left: 12px;
          border: 1px solid #E3C990;
          border-radius: 5px;

          font-size: 18px;
          font-family: Kano;
          font-weight: bold;
          color: #FFFFFF;
          cursor: pointer;
        }
        .active {
          background: #E3C990;
          color: #000000;
        }
      }
    }

    .bond-border-container {
      padding: 14px 19px 20px 12px;
      background: rgba(61, 61, 61, .22);
      border: 1px solid rgba(255, 255, 255, .22);
      border-radius: 10px;
    }

    .bond-bot {
      width: 100%;

      .bot-left {
        width: 396px;
      }

      .bot-right {
        width: 486px;
        // margin-right: 80px;
      }

      .bond-table {
        margin-top: 27px;
        font-family: Myriad Pro;
        font-weight: 400;
        text-align: center;

          .bond-table-header {
            margin-bottom: 14px;
            display: flex;
            justify-content: flex-start;
            font-size: 18px;
            color: #A1A1A1;

            .header-icon {
              width: 25%;
            }
            .header-bond {
              width: 40%;
            }
            .header-price {
              width: 25%;
            }
            .header-20 {
              width: 20%;
            }
          }

        .bond-table-body {

          .body-item {
            height: 70px;
            margin-bottom: 20px;
            padding: 8px;
            background: rgba(61, 61, 61, 0.3);
            border-radius: 5px;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 16px;
            cursor: pointer;

            .body-item-icon {
              width: 25%;
            }
            .body-item-bond {
              width: 40%;
            }
            .body-item-price {
              width: 25%;
            }
            .body-item-20 {
              width: 20%;
            }
          }

          .body-item-active {
            background: rgba(61, 61, 61, 0.9);
            color: #E3C990;
          }
        }
      }
    }
  }

  .redeem-table-container {
    padding: 12px;
    margin-bottom: 12px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  .bond-redeem-table {
    flex: 1;
    font-family: Myriad Pro;
    font-weight: 400;
    text-align: center;

    .bond-table-header {
      margin-bottom: 14px;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      color: #A1A1A1;

      .header-25 {
        width: 25%;
      }
      .header-33 {
        width: 33.33%;
      }
    }

    .bond-table-body {

      .body-item {
        height: 30px;
        padding: 8px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
        cursor: pointer;

        .body-item-25 {
          width: 25%;
          display: flex;
          justify-content: center;
        }

        .body-item-33 {
          width: 33.33%;
        }
      }
    }
  }

  .MuiTableBody-root .MuiTableRow-root {
    padding: 10px 30px 10px 16px !important;
    background: rgba(61, 61, 61, 0.3) !important;
    border-radius: 10px !important;
    border-bottom: 30px solid rgba(0, 0, 0, 0) !important;

    &:hover {
      background: rgba(61, 61, 61, 0.9) !important;
    }
  }
}

.width100 {
  width: 100% !important;
}

#bond-claim-btn {
  padding-left: 40px;
  padding-right: 40px;
}

#bond-claim-autostake-btn {
  width: 293px !important;
  border-radius: 24px !important;
}

.transaction-button {
  // min-width: 185px !important;
  // max-width: 408px;
  width: 100% !important;
	height: 50px !important;
	max-height: 50px !important;
	margin-bottom: 28px;
  // margin: 5px !important;
}

.redeem-button {
  // width: 120px !important;
  height: 39px !important;
  border-radius: 5px !important;
}

#choose-bond-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  .MuiTableCell-head {
	  color: #768299;
  }
  .ohm-card .bond-hero h4 {
    font-weight: 600 !important;

    .MuiSkeleton-root {
      margin: auto;
    }
  }
  .claim-bonds-card {
    .global-claim-buttons {
      width: 100%;
      margin: 15px 0px;
      button {
        max-width: 233px;
      }
    }
    .small.global-claim-buttons {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      button {
        max-width: unset;
        width: 90%;
      }
    }
  }

  .bond-data-card {
    margin: auto;
    .ohm-pairs {
      display: flex;
      margin-right: 15px;
    }
    .bond-pair {
      display: flex !important;
      align-items: center;
      justify-content: left;
      margin-bottom: 15px;
      .MuiBox-root {
        margin-right: 10px;
      }
      .bond-name {
        svg {
          margin-left: 5px;
          vertical-align: middle;
          font-size: 15px;
        }
      }
    }
    .data-row {
      margin-bottom: 13px;

      .bond-price {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
        }
      }
    }
    .bond-link {
      margin-top: 10px;
      text-align: center;
      a {
        width: 96%;
      }
    }
  }

  .currency-icon {
    display: inline-block;
    min-width: 15px;
  }

  .MuiTable-root {
    margin: 10px 0px;
  }

  .claim-bond-card-buttons {
    flex-wrap: wrap;
    width: 100%;
    button {
      min-width: 48%;
      margin: 5px 0px;
    }
  }

  .MuiTableCell-root {
    p {
      margin: 0 !important;
      display: flex !important;
      align-items: center;
      // justify-content: flex-start;
      white-space: nowrap;
      img {
        margin-right: 3px;
      }
    }
  }

  .MuiTableBody-root .MuiTableRow-root .MuiTableCell-alignLeft.bond-name-cell {
    justify-content: space-between;
    display: flex;
    div.bond-name {
      width: 60%;
      margin-left: 5px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      align-items: flex-start;
      p {
        margin-bottom: unset !important;
        line-height: 140% !important;
      }
      svg {
        margin-left: 3px !important;
        font-size: 1.2em !important;
      }
    }
  }
}

.tablet #choose-bond-view {
  .claim-bond-card-buttons {
    button {
      width: 40%;
    }
  }
}

.mobile #choose-bond-view {
  .claim-bond-card-buttons {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
}

.ohm-card-containe {
  margin-bottom: 20px !important;
}
