.chart__container {
  border-radius: 8px;
  padding: 30px;
  background-color: #273241;
  // width: 700px;
  display: inline-block;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.2);
}

.row {
  display: table-cell;
  vertical-align: middle;
}