@media only screen and (max-width: 1341px) {
  .stake-view {
    width: 100%;
    margin-top: 20px;
    padding: 0 20px 0 16px;

    .bg-container {
      background: #1B283F;
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
      border-radius: 10px;
    }

    .color-bg-container {
      border: NaNpx solid;
      border-image: linear-gradient(0deg, #269AD2, #13FAD1) 10 10;
      background: linear-gradient(0deg, #163B4C 1%, #19403D 100%);
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
      border-radius: 10px;
    }

    .unity-tab-container {
      position: relative;
      margin-bottom: 23px;

      .unity-calculator {
        padding: 4px 5px 5px;

        img {
          width: 9px;
          height: 10px;
        }
      }
    }

    // .unity-top {
    //   flex-wrap: wrap;

    //   .top-item {
    //     width: 151px;
    //     height: 80px;

    //     .item-label {
    //       margin-bottom: 13px;
    //       font-size: 8px;
    //     }
    //     .item-value {
    //       font-size: 12px;
    //     }
    //   }

    //   .lock-container {
    //     width: 218px;
    //     height: 114px;
    //     padding: 12px 14px 8px;
    //     margin: 14px auto 0;

    //     .balance-title {
    //       margin-bottom: 10px;
    //       font-size: 11px;
    //     }

    //     .balance-table {
    //       margin-bottom: 5px;

    //       .table-header {
    //         .header-item {
    //           width: 40%;
    //           font-size: 8px;
    //         }
    //       }

    //       .table-body {
    //         margin-top: 8px;

    //         .item-container {
    //           height: 23px;
    //         }
    //         .body-item {
    //           width: 40%;
    //           font-size: 8px;
    //         }
    //       }
    //     }
    //   }
    // }

    .unity-bot {
      margin-top: 22px;
      padding: 36px 21px 30px 25px;
      background: #141E2F;
      box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
      border-radius: 10px;

      .mode-tab {

        .mode-item {
          height: 24px;
          padding: 6px;
          font-size: 12px;
        }
      }

      .mode-container {
        margin-bottom: 55px;
        flex-wrap: wrap;
        width: 100%;
      }

      .bot-data {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }

      .data-list {
        width: 100%;
        margin-top: 14px;
        padding: 16px 10px 0 15px;

        .left-item {
          margin-bottom: 32px;

          .item-label {
            font-size: 8px;
          }
          .item-value {
            font-size: 8px;
          }
        }
      }

      .unity-operate {
        width: 100%;
        padding: 14px 20px;

        .operate-title {
          margin-bottom: 15px;
          font-size: 10px;
        }
      }
    }
    
    .stake-view-container {
      width: 100%;
      padding: 16px 36px;
    }

    .stake-data-container {
      margin-bottom: 24px;

      .stake-data {
        width: 489px;
        height: 199px;
        padding-left: 18px;
        padding-top: 4px;
        padding-bottom: 8px;
        flex-wrap: wrap;
        background: rgba(61, 61, 61, 0.3);
        border-radius: 10px;

        .stake-data-item {
          width: 50%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .item-label {
            font-size: 14px;
            font-family: Kano;
            font-weight: 400;
            color: #8A8A8A;
            line-height: 35px;
          }

          .item-value {
            font-size: 24px;
            line-height: 17px;
            font-family: Helvetica;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
    }


    .amount-input {
      width: 100%;
      height: 27px;
      font-size: 9px;
    }
    .stake-button {
      height: 28px;
      min-height: 28px;
      margin-top: 21px;
      font-size: 10px;
    }
    
    .stake-note {
      width: 80%;
      text-align: center;
      font-style: italic;
    }
    
    .claim-button {
      width: 100%;
      height: 24px;
      // min-width: 60px;
      padding: 4px 12px;
      border-radius: 5px;
      font-size: 12px;
      font-family: Poppins;
      color: #FFFFFF;
      cursor: pointer;

      // &:hover {
      //   color: #000;
      //   background: #6AE6F1;
      // }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .stake-view {
    .unity-top {
      flex-wrap: wrap;

      .top-item {
        width: 151px;
        height: 80px;

        .item-label {
          margin-bottom: 13px;
          font-size: 8px;
        }
        .item-value {
          font-size: 12px;
        }
      }

      .lock-container {
        width: 65%;
        height: 114px;
        // padding: 12px 14px 8px;
        margin: 14px auto 0;

        
        .lock {
          width: 100%;
          height: 100%;
          padding: 12px 14px 8px;
          }

        .balance-title {
          margin-bottom: 10px;
          font-size: 11px;
        }

        .balance-table {
          margin-bottom: 5px;

          .table-header {
            .header-item {
              width: 40%;
              font-size: 8px;
            }
          }

          .table-body {
            margin-top: 8px;

            .item-container {
              height: 23px;
            }
            .body-item {
              width: 40%;
              font-size: 8px;
            }
          }
        }
      }
    }

    .unity-bot {
      .mode-tab {
        width: 100%;
        justify-content: center;
        
        .mode-item {
          height: 24px;
          padding: 6px;
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .stake-view {
    .unity-top {
      .lock-container {
        width: 218px;
        height: 114px;
      }
    }
  }
  .max-button {
    font-size: 10px !important;
  }
}
