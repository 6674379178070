#footer-view {
  width: 100%;
  padding: 78px 0 34px;
  background: #050C19;
  margin-top: 40px;

  .footer-container {
    width: 1341px;
    margin: 0 auto;
  }

  .footer-content {
    padding-bottom: 20px;
    border-bottom: 2px solid #6A6A6A;

    .footer-left {
      max-width: 348px;
      padding-top: 12px;

      .desc {
        margin: 34px 0;
        font-size: 16px;
        line-height: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #70798A;
      }

      .logo {
        width: 140px;
        // height: 34px;
      }

      .icon {
        width: 40px;
        height: 40px;
        margin-right: 11px;
        cursor: pointer;
      }
    }

    .footer-right {
      margin-left: 11.19%;
      width: 100%;

      .title {
        font-size: 18px;
        font-family: Poppins;
        font-weight: 300;
        color: #FFFFFF;
      }

      .right-item {
        flex-direction: column;
      }
      .item {
        margin-top: 25px;

        div {
          margin-bottom: 21px;
          font-size: 16px;
          font-family: Poppins;
          font-weight: 300;
          color: #70798A;
          cursor: pointer;

          &:hover {
            color: #FFF;
            // font-size: 18px;
          }
        }
      }

      .active-item {
        div {
          font-weight: 600;
          color: #FFF;
        }
      }
    }
  }

  .footer-symbol {
    margin-top: 12px;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 300;
    color: #70798A;
  }

  .lang-select {
    width: 231px;
    height: 43px;
    padding: 0 8px 0 16px;
    margin-top: 23px;
    border: 1px solid rgba($color: #FFF, $alpha: 0.2);
    border-radius: 21px;

    font-size: 16px;
    font-family: Poppins;
    font-weight: 300;
    color: #9B9DAA;
    cursor: pointer;

    img {
      width: 28px;
      height: 28px;
    }
  }



  .MuiSelect-select:focus {
    background: none;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
}

.lang-dropdown {
  border-radius: 10px;

  ul {
    background: #141E2F;
    border-radius: 10px;
    color: #FFF;

    .ant-dropdown-menu-item {
      border-radius: 10px;
      padding: 12px !important;
    }

    .ant-dropdown-menu-item-active {
      background: transparent !important;

      .ant-dropdown-menu-title-content {
          background: linear-gradient(58deg, #6AE6E8 0%, #75FAAB 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
  }

  .ant-dropdown-menu-title-content {
    font-size: 1rem;
    line-height: 1.25rem;
    color: #FFF !important;
  }
}
