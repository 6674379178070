@import './assets/fonts/fonts.scss';

// * {
  // outline: none;
  // -ms-overflow-style: auto !important; /* IE and Edge */
  // scrollbar-width: none !important; /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
// }

h1, h2, h3, h4, h5, h6 {
  color: #fff;
}

html,
body,
#root {
  font-family: "Poppins-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow: hidden;
}

body {
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width:0px;
}

.app {
  // height: 100vh;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  // background: #0396B4;
  background: url(assets/background.png);
  background-position: center;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-family: "Poppins-Regular";
  // overflow: hidden;
  transition: all ease 0.33ms;
  position: relative;
}

.app-content {
  display: flex;
  justify-content: center;
}

.leftIcon {
	position: fixed;
	left: 280px;
	bottom: 0;
	z-index: -1;
}
.rightIcon {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.MuiButtonBase-root {
	display: flex;
	align-items: center;
  // width: auto !important;
}

@media only screen and (max-width: 960px) {
	.leftIcon,
	.rightIcon {
		display: none;
	}
}

.pointer {
  cursor: pointer;
}

.fullwidth {
  width: 100%;
}

.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-align-items-center {
	align-items: center;
}

.flex-align-items-start {
	align-items: flex-start;
}

.flex-align-items-end {
	align-items: flex-end;
}

.flex-justify-content-center {
	justify-content: center;
}

.flex-justify-content-between {
	justify-content: space-between;
}

.flex-justify-content-around {
	justify-content: space-around;
}

.flex-justify-content-end {
	justify-content: flex-end;
}

.AdobeHeitiStd-Regular { 
  font-family: "AdobeHeitiStd-Regular" !important;
}

.Kano-regular { 
  font-family: "Kano" !important;
}

.Poppins {
  font-family: "Poppins";
}

.Arial-Black { 
  font-family: "ArialBlack" !important;
}

.ml-12 {
	margin-left: 12px!important;
}

.ml-20 {
	margin-left: 20px!important;
}

.mt-12 {
	margin-top: 12px!important;
}

.font-weight-5 {
	font-weight: 500!important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.ohm-card {
  max-width: 833px;
  .card-header {
    width: 100%;
    min-height: 33px;
    margin-bottom: 10px;
    h5 {
      font-weight: 600 !important;
    }
  }

  .help-text {
    margin: 10px 0px;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 12px 0px;
  }

  .data-row-centered {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 12px 0px;
    .marginedBtn {
      margin: 0.3rem;
    }
  }
}

.ohm-pairs {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.text-center {
  text-align: center;
}

a:hover svg {
  color: inherit;
}
.font-14 {
  font-size: 14px;
}
.font-weight-b {
  font-weight: bold;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.MuiDialog-root {
  z-index: 99 !important;
}
.ant-notification-notice-warning.ant-notification-notice{
	background: #F63C45!important;
}
.ant-notification-notice-info.ant-notification-notice {
	background: #2F86FF!important;
}
.ant-notification-notice-success.ant-notification-notice {
	background: #8CF6F4!important;
}
.ant-notification-notice {
	min-width: 246px!important;
	border-radius: 10px!important;
	min-height: 70px;
	padding: 0;
}
.ant-notification-notice-description{
	margin-left: 0!important;
	text-align: center;
	font-family: 'Inter'-apple-system,BlinkMacSystemFont!important ;
	font-size: 18px!important;
	font-weight: bold!important;
}
.ant-notification-notice-icon,
.ant-notification-notice-close{
	display: none!important;
}

.MuiButton-outlinedPrimary {
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 5px;
    background-color: #081429;
    z-index: -1;
  }
}
