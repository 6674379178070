@media only screen and (max-width: 500px) {
	.ido-info-view {
		margin-right: 0;

		.ido-top {
			border-bottom: none;

			.ido-finish {

				img {
					width: 100%;
					height: 192px;
					margin-top: 12px;
				}
			}
		}
	
		.ido-bot {
			margin-top: 0;
			padding: 40px 30px 50px 22px;
			// background: rgba(255, 255, 255, .05);
			// border-radius: 30px 30px 0px 0px;
	
			.bot-left {
				width: 100%;
				
				.left-item {
					margin-bottom: 6px;
				}
	
				.date-container {
					margin-top: 20px;
					font-size: 12px;
					font-family: Poppins;
					font-weight: 500;
					color: #E1E1E1;
	
					img {
						margin-right: 12px;
					}
				}
			}
	
			.bot-right {
				transform: scale(1);
	
				.my-balance {
					margin-bottom: 12px;
					font-size: 14px;
	
					:nth-child(1) {
						font-family: Poppins;
						font-weight: 300;
						color: #979797;
					}
					:nth-child(2) {
						font-family: Helvetica;
						font-weight: 300;
					}
				}
			}
	
			.check-container {
				margin-bottom: 42px;
				font-size: 16px;
				font-family: Poppins;
	
				.check-icon {
					// width: 15px;
					// height: 15px;
					margin-right: 15px;
				}
			}
		}
	
		img {
			width: 15px;
			height: 15px;
		}
	
		.ido-input {
			width: 322px;
			margin-bottom: 24px;
			border-radius: 10px;
		}
		.ido-button {
			width: 100%;
			height: 54px;
			border-radius: 10px;
		}
	}
	
}
