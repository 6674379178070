#ohm-menu-button {
  padding: 10px;
  background: none;
  border: 2px solid #575757;
  border-radius: 5px;
  font-size: 18px;
  color: #FFFFFF;  
}
.ohm-menu-button-hover {
  z-index: 9;
}
#lang-popper,
#ohm-popper-pending {
  z-index: 5;
  background-color: #00000003;
  backdrop-filter: blur(33px);
  -webkit-backdrop-filter: blur(33px);
  margin-top: 10px;
  border-radius: 10px;
  .MuiPaper-root.ohm-menu {
    padding: 6px;
    display: flex;
    flex-direction: column;
    width: 60px;
    button {
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 4px;
      padding-left: 16px;
      &:not(:hover) {
        background-color: #00000003 !important;
      }
    }
    a {
      svg {
        vertical-align: middle;
        font-size: 20px;
        margin-left: 3px;
      }
    }
    hr {
      margin-bottom: 18px;
    }
  }

  .add-tokens {
    > p {
      margin: 15px 0px 5px 0px;
      text-align: left;
      padding-left: 16px;
    }
  }

  .buy-tokens {
    padding-bottom: 8px;
  }
}
.current-lang-icon {
  width: 32px;
  height: 22px;
}
.lang-icon {
  width: 30px;
  height: 20px;
  margin: 0 auto;
}
.icon-button {
  width: 100%;
  padding: 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .current-lang-icon {
    width: 16px;
    height: 14px;
    // width: 8px;
    // height: 8px;
  }
  // .lang-icon {
  //   width: 8px;
  //   height: 8px;
  // }
  #ohm-menu-button {
    padding: 4px;
    border: 1px solid #575757;
  }
}
