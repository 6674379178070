.coming-soon {
  .MuiDialog-paperWidthSm {
    width: 300px
  }

  .coming-content {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    color: #000;
  }

  .MuiSnackbarContent-root {
    // background: rgba(246, 10, 102, 0.9) !important;

    border: NaNpx solid;
    border-image: linear-gradient(0deg, #6AE2EF, #74F9AC) 10 10;
    background: linear-gradient(90deg, #6AE5EA 0%, #75FAAA 100%);
    // border-radius: 5px;
    font-weight: 400;
    color: #000000;
  }
}
