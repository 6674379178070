.ape-tabs {
  border-bottom: 1px solid #6A6A6A;

  .tab-item {
    padding-bottom: 18px;
    margin-right: 33px;
    font-size: 32px;
    font-family: Poppins;
    font-weight: 300;
    color: #FFF;
    cursor: pointer;
  }
  .tab-item-active {
    padding-bottom: 18px;
    color: #FFFEFE;
    border-bottom: 2px solid;
    border-image: linear-gradient(0deg, #69E2ED, #5EC88D) 10 10;
  }
}

@media only screen and (max-width: 600px) {
  .ape-tabs {
  
    .tab-item {
      margin-right: 20px;
      padding-bottom: 8px;
      font-size: 16px;
    }
    .tab-item-active {
      padding-bottom: 8px;
    }
  }
  
}
