.selectBox {
	position: relative;
	.selectVal {
		padding-left: 12px;
		margin-left: 6px;
		border-left: 1px solid #93AEBC;
		:hover {
			cursor: pointer;
		}
		.DownIcon {
			width: 20px;
			height: 20px;
		}
		.select-caret {
			transition: transform .3s;
			transform: rotate(180deg);
			cursor: pointer;
		}
		.MuiTypography-h4 {
			font-size: 1.1rem!important;
		}
	}
	.selectList {
		display: none;
		position: absolute;
		top: 40px;
		z-index: 999;
		background-color: #FFFFFF;
		border-radius: 4px;
		padding: 12px 0;
		.selectItem {
			padding: 0px 16px;
			:hover {
				cursor: pointer;
			}
		}
	}
	.selectListDark {
		background-color: #242426!important;
	}
}

.show {
	display: block!important;
}


.bond-data {
	width: 486px;
	margin-top: 20px;

	.data-row {
		margin-top: 12px;
		display: flex;
		justify-content: space-between;

		.bond-label {
			font-size: 18px;
			font-family: Kano;
			font-weight: 400;
			color: #8A8A8A;
			line-height: 35px;
		}

		.bond-value {
			font-size: 18px;
			font-family: Helvetica;
			font-weight: bold;
			color: #FFFFFF;
		}
	}
}

.data-row-bg {
	padding: 0 12px;
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	background: rgba(61, 61, 61, 0.5);
	border-radius: 10px;

	.bond-label {
		font-size: 16px !important;
		line-height: 31px !important;
	}

	.bond-value {
		font-size: 16px !important;
	}
}

// .bond-input {
// 	border: none !important;
// 	// border: 2px solid #3D3D3D !important;
// }