.dashboard-view {
  width: 1341px;
  margin-top: 63px;
  // margin-right: 12px;
  // padding: 30px 86px 0 64px;

  .bg-container {
    background: #141E2F;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.26);
    border-radius: 10px;
  }

  .dashboard-title {
    margin-bottom: 35px;

    h2 {
      font-family: Poppins;
    }
  }

  .chart-container {
    padding: 16px;

    img {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }
  }

  .card-container {
    flex: 1;
    // margin-left: 16px;
    .card-item {
      // min-width: 190px;
      width: 287px;
      height: 153px;
      margin-left: 40px;
      margin-bottom: 20px;
      // padding: 10px 13px 12px 12px;
      text-align: left;

      :nth-child(1) {
        font-family: Poppins;
        font-size: 12px;
      }

      h6 {
        font-size: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #B1B9CC;
      }

      h5 {
        font-size: 32px;
        margin-top: 20px;
        font-family: Poppins;
        font-weight: bold;
        color: #FFF;
      }

      .img1 {
        width: 39px;
        height: 44px;
      }
      .img2 {
        width: 45px;
        height: 42px;
      }
      .img3 {
        width: 49px;
        height: 51px;
      }
      .img4 {
        width: 36px;
        height: 36px;
      }
    }

    :nth-child(3) {
      margin-bottom: 0;
    }

    :nth-child(4) {
      margin-bottom: 0;
    }
  }

  .data-container {
    margin-top: 27px;
    border-radius: 10px;
    background: #141E2F;


    .data-item {
      width: 95%;
      margin: 0 auto;
      padding: 28px 0;
      // background: rgba(0, 0, 0, .25);
      // border-radius: 10px;
      border-bottom: 1px solid #3D4F6D;

      h5 {
        font-size: 20px;
        font-family: Poppins;
        font-weight: 300;
        color: #FFFFFF;
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
}
