.invite-modal {
  width: 639px;

  img {
    width: 100%;
    height: 264px
  }

  .invite-address {
    font-size: 12px;
    font-family: Poppins;
    font-weight: 300;
    text-decoration: underline;
    color: #FFFEFE;
  }

  .no-content {
    text-decoration: none;
  }

  .invite-desc {
    margin: 10px 0 20px;
    text-align: center;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 300;
    color: #B5C3E7;
  }

  .invite-content {
    padding: 24px 0 13px;
    background-color: #23335B;
  }

  .invite-button {
    width: 146px;
    height: 39px;
    font-size: 18px;
  }

  .radio-container {
    padding: 20px;

    .address-input {
      margin-top: 20px !important;
    }
  }

  .ant-radio-checked {

    .ant-radio-inner {
      border-color: #6AE6E8 !important;
    }

    .ant-radio-inner::after {
      background-color: #6AE6E8 !important;
    }
  }

  .ant-radio-wrapper {
    :last-child {
      color: #FFF !important;
    }
  }
}

.ant-modal-wrap {
  background-color: rgba($color: #000000, $alpha: 0.7);
}

@media only screen and (max-width: 500px) {
  .invite-modal {

    .invite-desc {
      margin: 20px 0 27px;
    }

    .invite-content {
      padding: 30px 0 23px;
    }
  }
}
